import { RadioGroupProps } from '@amzn/awsui-components-react/polaris/radio-group';
import { CollectionPreferencesProps } from '@amzn/awsui-components-react/polaris/collection-preferences';

export const paginationLabels = {
  nextPageLabel: 'Next page',
  pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
  previousPageLabel: 'Previous page',
};

export const CUSTOM_PREFERENCE_OPTIONS: RadioGroupProps.RadioButtonDefinition[] =
  [
    { value: 'table', label: 'Table' },
    { value: 'cards', label: 'Cards' },
  ];

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
  pageSize: 30,
  visibleContent: [
    'id',
    'domainName',
    'deliveryMethod',
    'sslCertificate',
    'status',
    'state',
  ],
  wrapLines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value,
};
