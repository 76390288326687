import type { UpdateSliceAssociationRequest } from '@amzn/allocations-service';

export const getUpdateSliceAssociationRequest = (
  sliceId: string,
  dmId: string,
  currentDmId: string,
): UpdateSliceAssociationRequest => ({
  sliceId,
  dmId,
  currentDmId,
});
