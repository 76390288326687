import React, { useMemo } from 'react';
import BreadcrumbGroup, {
  BreadcrumbGroupProps,
} from '@cloudscape-design/components/breadcrumb-group';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TFunc } from 'src/common/types';

const breadcrumbPathMappings = (
  t: TFunc,
  id: string,
): Record<string, BreadcrumbGroupProps.Item[]> => ({
  '/driver/create': [{ text: t('create'), href: '' }],
  '/driver/update': [
    { text: t('manage'), href: '/driver/manage' },
    { text: id, href: '' },
  ],
  '/driver/manage': [{ text: t('manage'), href: '' }],
  '/driver/details': [
    { text: t('manage'), href: '/driver/manage' },
    { text: id, href: '' },
  ],
  '/allocation/create': [{ text: t('create'), href: '' }],
  '/allocation/update': [
    { text: t('manage'), href: '/allocation/manage' },
    { text: id, href: '' },
  ],
  '/allocation/manage': [{ text: t('manage'), href: '' }],
  '/allocation/details': [
    { text: t('manage'), href: '/allocation/manage' },
    { text: id, href: '' },
  ],
  '/general/reports': [{ text: t('reports'), href: '' }],
  '/general/mappings': [{ text: t('mappings'), href: '' }],
  '/slice/single': [
    { text: t('configure-slice-allocation-methodology'), href: '' },
  ],
  '/slice/group': [{ text: t('configure-slice-group'), href: '' }],
  '/slice/allocation/manage': [
    { text: t('manage-slice-allocation'), href: '' },
  ],
  '/slice/report': [{ text: t('view-slice-report'), href: '' }],
});

/**
 * This components displays series of navigational links in a hierarchical list
 */
export const Breadcrumbs = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const mappings = useMemo(
    () => breadcrumbPathMappings(t, location.pathname.split('/').pop() ?? ''),
    [location.pathname, t],
  );

  const handleNavigation = (
    event: CustomEvent<BreadcrumbGroupProps.ClickDetail>,
  ) => {
    event.preventDefault();
    navigate(event.detail.href as string);
  };

  const paths: BreadcrumbGroupProps.Item[] = [
    {
      text: t('home'),
      href: '/',
    },
  ];

  const pathKey = Object.keys(mappings).find((path) =>
    location.pathname.includes(path),
  );

  return (
    <BreadcrumbGroup
      items={pathKey ? paths.concat(mappings[pathKey]) : []}
      onFollow={handleNavigation}
      ariaLabel="Breadcrumbs"
    />
  );
};
