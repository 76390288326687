import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  LandingPageOption,
  landingPageOptions,
} from 'src/pages/landing-page/config';

interface SelectedOption {
  label: LandingPageOption;
  value: string;
}

interface SelectionContextType {
  landingPageOption: SelectedOption;
  setLandingPageOption(index: number): void;
}

const defaultSelectedOption: SelectedOption = {
  label: landingPageOptions[0].label,
  value: landingPageOptions[0].value,
};

/**
 * React context for Landing page selection
 */
const SelectionContext = createContext<SelectionContextType>({
  landingPageOption: defaultSelectedOption,
  setLandingPageOption: () => {},
});

/**
 * Props for LandingPageSelectionProvider
 */
interface LandingPageSelectionProviderProps {
  children: ReactNode;
}

export const LandingPageSelectionProvider: FC<
  LandingPageSelectionProviderProps
> = ({ children }) => {
  const [selectedOption, setSelectedOption] = useState(defaultSelectedOption);

  const updateOption = useCallback((index: number) => {
    setSelectedOption({
      label: landingPageOptions[index].label,
      value: landingPageOptions[index].value,
    });
  }, []);

  return (
    <SelectionContext.Provider
      value={{
        landingPageOption: selectedOption,
        setLandingPageOption: updateOption,
      }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

/**
 * React hook to use landing page selection context
 */
export const useLandingPageSelectionContext = () =>
  useContext(SelectionContext);
