import { DimensionValue } from '@amzn/allocations-service';
import { TreeNode } from 'src/common/components/tree-select/TreeSelect';

/**
 * This function is used to convert the dimension values to tree nodes
 * @param dimensionValues - The dimension values
 * @returns - The tree nodes
 */
export const getTreeNodesFromDimensionValues = (
  dimensionValues: DimensionValue[] = [],
): TreeNode[] => {
  if (dimensionValues.length === 0) {
    return [];
  }

  return dimensionValues
    .map(({ fieldId, fieldName, keyFull, isLeaf, level }) => ({
      id: `${fieldId} - ${fieldName}`,
      label: `${fieldId} - ${fieldName}`,
      key: keyFull,
      leaf: isLeaf,
      data: { level },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

/**
 * This function is used to add the children to the tree nodes based on the keyFull
 * @param nodes - The tree nodes
 * @param keyFull - The keyFull of the node to be expanded
 * @param children - The children to be added
 * @param expanded - Whether the node should be expanded or not
 * @returns - The updated tree nodes
 */
export const getTreeNodesWithChildren = (
  nodes: TreeNode[],
  keyFull: string,
  children: TreeNode[],
  expanded: boolean,
): TreeNode[] => {
  if (!nodes.length) {
    return children;
  }

  const result: TreeNode[] = [];

  for (const node of nodes) {
    if (node.key === keyFull) {
      result.push({ ...node, children, expanded });
    } else if (keyFull.includes(node.key as string)) {
      const updatedChildren = getTreeNodesWithChildren(
        node.children ?? [],
        keyFull,
        children,
        expanded,
      );
      result.push({ ...node, children: updatedChildren });
    } else {
      result.push(node);
    }
  }

  return result;
};

/**
 * This function is used to expand the tree nodes based on the keyFull
 * @param nodes - The tree nodes
 * @param keyFull - The keyFull of the node to be expanded
 * @param expanded - Whether the node should be expanded or not
 * @returns - The updated tree nodes
 */
export const getTreeNodes = (
  nodes: TreeNode[],
  keyFull: string,
  expanded: boolean,
): TreeNode[] => {
  const result: TreeNode[] = [];

  for (const node of nodes) {
    if (node.key === keyFull) {
      result.push({ ...node, expanded });
    } else if (keyFull.startsWith(node.key as string)) {
      const updatedChildren = getTreeNodes(
        node.children ?? [],
        keyFull,
        expanded,
      );
      result.push({ ...node, children: updatedChildren });
    } else {
      result.push(node);
    }
  }

  return result;
};
