import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, Select } from '@cloudscape-design/components';
import Spinner from '@cloudscape-design/components/spinner';
import { SelectChangeEvent } from 'src/common/types/Events';
import { OptionDefinition } from 'src/common/types/OptionDefinition';

const SliceSelector = (props: {
  sliceSelectionCallback: (selectedSlice: OptionDefinition) => void;
  sliceOptions: OptionDefinition[];
  isFetchingSlices?: boolean;
}) => {
  const { t } = useTranslation();
  const [selectedSlice, setSelectedSlice] = useState<OptionDefinition | null>(
    null,
  );

  useEffect(() => {
    setSelectedSlice(null);
  }, [JSON.stringify(props.sliceOptions)]); //This ensures that actual content changes trigger the useEffect

  const handleSliceChange = (event: SelectChangeEvent) => {
    const selectedSlice = event.detail.selectedOption;
    if (
      selectedSlice.label !== undefined &&
      selectedSlice.value !== undefined
    ) {
      setSelectedSlice({
        label: selectedSlice.label,
        value: selectedSlice.value,
      });
      props.sliceSelectionCallback(selectedSlice as OptionDefinition);
    }
  };

  return (
    <FormField label={t('Slice Name')} stretch>
      {props.isFetchingSlices && (
        <Spinner data-testid="slice_selector_spinner" />
      )}
      <Select
        data-testid="slice_selector"
        options={props.sliceOptions ? props.sliceOptions : []}
        onChange={handleSliceChange}
        disabled={props.sliceOptions.length === 0 || props.isFetchingSlices}
        selectedOption={selectedSlice}
        placeholder={t('Choose a slice')}
      />
    </FormField>
  );
};

export default SliceSelector;
