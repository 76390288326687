import { Storage } from 'aws-amplify';

/**
 * This function will return the S3 file
 */
export const getS3File = (fileOptions: {
  bucket: string;
  name: string;
  path: string;
}) =>
  Storage.get(fileOptions.name, {
    bucket: fileOptions.bucket,
    download: true,
    customPrefix: { public: fileOptions.path },
    level: 'public',
  });

/**
 * Utility function to initiate download of file/blob
 */
export const downloadBlob = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
  URL.revokeObjectURL(url);
  return a;
};

/**
 * This function will retrieve the file from S3 & initiate download.
 */
export const downloadFile = async (fileOptions: {
  bucket: string;
  name: string;
  path: string;
}) => {
  const result = await getS3File(fileOptions);
  if (!result.Body) {
    return;
  }

  downloadBlob(result.Body as Blob, fileOptions.name);
};

/**
 * This function will retrieve the file using the S3 presigned url & initiate download.
 */
export const downloadFileWithPresignedUrl = async (
  presignedUrl: string,
  fileName: string,
) => {
  const response = await fetch(presignedUrl);
  const blob = await response.blob();
  downloadBlob(blob, fileName);
};

/**
 * This function will upload file to S3 file path
 * @param fileOptions File Upload Options
 * @returns Promise
 */
export const uploadFile = (fileOptions: {
  bucket: string;
  data: any;
  name: string;
  path: string;
}) =>
  Storage.put(fileOptions.name, fileOptions.data, {
    bucket: fileOptions.bucket,
    customPrefix: { public: fileOptions.path },
    acl: 'bucket-owner-full-control',
  });
