import {
  CreateTaskRequest,
  TaskExecution,
  TaskInput,
  TaskWithLatestExecutionState,
  UpdateTaskRequest,
  Schedule as TaskSchedule,
} from '@amzn/taskscheduling-service';
import { EventSummary } from '@amzn/allocations-service';
import { TaskExecutionItem } from '../types/TaskExecutionItem';
import { EventStatus } from '../types/EventStatus';
import { TaskWithStatus } from '../types/TaskWithStatus';
import { convertTime, formatDate, getTimezoneOffset } from './date';
import { Schedule } from 'src/common/types';
import { Config } from 'src/common/types/Config';
import { getName } from 'src/common/utils/filters';
import { Business } from 'src/legacy-allocations/pages/create-allocation-page/config';

const getSchedule = (schedule: Schedule) => ({
  workdayStart: Number(schedule.workdayStart?.value),
  workdayEnd: Number(schedule.workdayEnd?.value),
  scheduleTime:
    schedule.time?.value &&
    convertTime(schedule.time.value, getTimezoneOffset()),
});

export const getDriverTaskInput = (config: Config): TaskInput => ({
  driverTaskInput: {
    methodology: config.methodology?.value,
    business: config.business?.value,
    region: config.region?.value,
    country: config.country.map((option) => option.value ?? ''),
    scenario: config.scenario?.value,
  },
});

export const getAllocationTaskInput = (config: Config): TaskInput => {
  if (config.business?.value === Business.AB) {
    return {
      modeledAllocationTaskInput: {
        methodology: config.methodology?.value,
        business: config.business?.value,
        region: config.region?.value,
        country: config.country.map((option) => option.value ?? ''),
        scenario: config.scenario?.value,
        modelingInput: config.modelingInput,
      },
    };
  }
  return {
    allocationTaskInput: {
      methodology: config.methodology?.value,
      business: config.business?.value,
      region: config.region?.value,
      country: config.country.map((option) => option.value ?? ''),
      scenario: config.scenario?.value,
    },
  };
};

export const getSlicedAllocationTaskInput = (
  scenario: string | undefined,
  sliceSequences: string[] | undefined,
  environment: string | undefined,
  methodology: string | undefined,
  business: string | undefined,
  region: string | undefined,
): TaskInput => ({
  slicedAllocationTaskInput: {
    scenario,
    sliceSequence: sliceSequences,
    environment,
    methodology,
    business,
    region,
  },
});

export const getCreateTaskRequest = (
  config: Config,
  schedule: Schedule,
  taskInput: TaskInput,
): CreateTaskRequest => ({
  name: getName(config),
  description: config.description,
  input: taskInput,
  schedule: schedule.enable ? getSchedule(schedule) : undefined,
});

export const getUpdateTaskRequest = (
  taskId: string | undefined,
  description: string,
  input: TaskInput,
  schedule: Schedule,
): UpdateTaskRequest => ({
  taskId,
  description,
  input,
  schedule: schedule.enable ? getSchedule(schedule) : undefined,
});

export const getUpdateTaskRequestWithSchedule = (
  taskId: string | undefined,
  description: string | undefined,
  input: TaskInput | undefined,
  schedule: TaskSchedule | undefined,
): UpdateTaskRequest => ({
  taskId,
  description,
  input,
  schedule,
});

export const getTaskExecutionItem = (
  executions: TaskExecution[] | undefined,
): TaskExecutionItem[] =>
  executions?.map((task) => {
    const latestEvent = task.executionEvents?.length
      ? task.executionEvents[task.executionEvents.length - 1]
      : undefined;
    return {
      taskId: task.taskId,
      taskVersion: task.taskVersion,
      executionId: task.executionId,
      executedBy: task.executedBy,
      runMode: task.runMode,
      time: task.executionStartTime
        ? formatDate(task.executionStartTime)
        : undefined,
      status: latestEvent?.status,
      metadata: latestEvent?.metadata,
      closeMonth: task.closeMonth,
    };
  }) ?? [];

export const requiresApproval = (events: EventSummary[] = []): boolean =>
  events.length > 0 &&
  events.some((event) => event.status === EventStatus.PENDING_REVIEW);

const getLatestExecutionStateStatus = (executionState?: TaskExecution) => {
  if (!executionState?.executionEvents?.length) {
    return undefined;
  }
  return executionState?.executionEvents[
    executionState.executionEvents?.length - 1
  ].status;
};

export const getTasksWithExecutionState = (
  taskList: TaskWithLatestExecutionState[],
): TaskWithStatus[] =>
  taskList.map(({ task, executionState }) => ({
    ...(Boolean(task) && task),
    status: getLatestExecutionStateStatus(executionState),
  }));
