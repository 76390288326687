import {
  CreateTaskRequest,
  CreateTaskResponse,
  DeleteTaskRequest,
  ExecuteTaskRequest,
  ExecuteTaskResponse,
  GetTaskExecutionStateRequest,
  GetTaskExecutionStateResponse,
  GetTaskRequest,
  GetTaskResponse,
  ListTaskExecutionsRequest,
  ListTaskExecutionsResponse,
  ListTasksWithLatestExecutionStateRequest,
  ListTasksWithLatestExecutionStateResponse,
  UpdateTaskRequest,
  ListTasksRequest,
  ListTasksResponse,
} from '@amzn/taskscheduling-service';
import { Api } from './Api';

const API = new Api('TaskSchedulingService');

export const TaskSchedulingServiceApi = {
  createTask(request: CreateTaskRequest): Promise<CreateTaskResponse> {
    return API.post('/task/create', request);
  },
  updateTask(request: UpdateTaskRequest): Promise<void> {
    return API.post('/task/update', request);
  },
  getTask(request: GetTaskRequest): Promise<GetTaskResponse> {
    return API.post('/task', request);
  },
  deleteTask(request: DeleteTaskRequest): Promise<void> {
    return API.post('/task/delete', request);
  },
  getTaskExecutionState(
    request: GetTaskExecutionStateRequest,
  ): Promise<GetTaskExecutionStateResponse> {
    return API.post('/task/execution', request);
  },
  executeTask(request: ExecuteTaskRequest): Promise<ExecuteTaskResponse> {
    return API.post('/task/execute', request);
  },
  listTaskExecutions(
    request: ListTaskExecutionsRequest,
  ): Promise<ListTaskExecutionsResponse> {
    return API.post('/task/execution/list', request);
  },
  listTaskWithLatestExecution(
    request: ListTasksWithLatestExecutionStateRequest,
  ): Promise<ListTasksWithLatestExecutionStateResponse> {
    return API.post('/task/listwithexecution', request);
  },
  listTask(request: ListTasksRequest): Promise<ListTasksResponse> {
    return API.post('/task/list', request);
  },
};
