import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import Spinner from '@cloudscape-design/components/spinner';
import { GetSliceRequest } from '@amzn/allocations-service';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import { ValueWithLabel } from 'src/common/components/ValueWithLabel';

const SliceInformationForDriverMapping = (props: {
  driverMappingId: string | null;
}) => {
  const { t } = useTranslation();
  const [driverMappingId] = useState<string | null>(props.driverMappingId);

  const getAssociatedSliceNames = async () => {
    const sliceIdsAssociatedWithDmId =
      await AllocationServiceApi.getSlicesAssociatedToDriverMapping({
        dmId: driverMappingId!,
      });
    const getSliceRequests: GetSliceRequest[] =
      sliceIdsAssociatedWithDmId.sliceIds!.map((sliceId) => ({ sliceId }));
    const slicesAssociatedWithDmId = await Promise.all(
      AllocationServiceApi.getSliceInformationBatch(getSliceRequests),
    );
    const commaSeparatedSliceNames = slicesAssociatedWithDmId
      .map((slice) => slice.sliceName)
      .join(', ');
    return commaSeparatedSliceNames;
  };

  const slicesAssociatedWithDmIdQuery = useQuery({
    queryKey: [
      QueryKey.GetSliceNamesAssociatedWithDriverMappingId,
      driverMappingId,
    ],
    queryFn: () => getAssociatedSliceNames(),
    enabled: !!driverMappingId,
  });

  return (
    <ValueWithLabel
      label={t('associated_slices')}
      data-testid="associated_slices"
      key={driverMappingId}
    >
      <>
        {slicesAssociatedWithDmIdQuery.isLoading && (
          <Spinner data-testid="associated_slices_spinner" />
        )}
        {!slicesAssociatedWithDmIdQuery.isLoading &&
          slicesAssociatedWithDmIdQuery.data}
      </>
    </ValueWithLabel>
  );
};

export default SliceInformationForDriverMapping;
