import {
  Action,
  GetPermissionsByBusinessRequest,
  GetPermissionsByBusinessResponse,
  HasPermissionRequest,
  HasPermissionResponse,
} from '@amzn/fae-auth-service';
import { Api } from './Api';
import { PnLPage } from 'src/common/constants/PnLPages';

const API = new Api('FAEAuthService');

// temporary placeholder for FA auth API and model
export interface HasPageAdminPermissionRequest {
  action: Action;
  page: PnLPage;
}

export interface HasPageAdminPermissionResponse {
  isAuthorized: boolean;
}

export const AuthServiceApi = {
  getPermissionsByBusinessRequest(
    request: GetPermissionsByBusinessRequest,
  ): Promise<GetPermissionsByBusinessResponse> {
    return API.post('/businessPermissions/get', request);
  },
  hasPermissions(
    request: HasPermissionRequest,
  ): Promise<HasPermissionResponse> {
    return API.post('/permission/check', request);
  },
  hasPageAdminPermissions(
    request: HasPageAdminPermissionRequest,
  ): Promise<HasPageAdminPermissionResponse> {
    return API.post('/pagePermission/check', request);
  },
};
