import React, { FC, lazy, startTransition, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, SpaceBetween } from '@cloudscape-design/components';
import { columnDefinitions } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';
import { filteringProperties } from 'src/common/components/driver-mapping-components/config/driver-mapping-filter-property';
import {
  DriverMappingAction,
  getDriverMappingData,
  getDriverMappingPath,
} from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { Table } from 'src/common/components/Table';
import { getContentDisplayOptions } from 'src/common/utils/table';
import { Config } from 'src/common/types/Config';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { downloadFile } from 'src/api/s3Api';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';

const DriverMappingModal = lazy(
  () =>
    import(
      'src/common/components/driver-mapping-components/DriverMappingModal'
    ),
);
interface DriverMappingStepProps {
  config: Config;
  isLoading: boolean;
  onConfigChange(action: { type: string; payload?: Partial<Config> }): void;
}

export const DriverMappingStep: FC<DriverMappingStepProps> = (props) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationContext();
  const [driverModalAction, setDriverModalAction] =
    useState<DriverMappingAction>();
  const [selectedItems, setSelectedItems] = useState<DriverMapping[]>([]);
  const colDefs = useMemo(() => columnDefinitions(t), [t]);
  const taskIdentifier = useMemo(
    () =>
      Object.keys(props.config?.modelingInput ?? {})[0] ||
      `template_${props.config.business?.value}_${props.config.region?.value}`,
    [
      props.config.business?.value,
      props.config?.modelingInput,
      props.config.region?.value,
    ],
  );

  const toggleModalDisplay = (action?: DriverMappingAction) => {
    startTransition(() => setDriverModalAction(action));
  };

  const handleDownload = async () => {
    try {
      await downloadFile({
        name: `${taskIdentifier}.json`,
        path: getDriverMappingPath(props.config),
        bucket: 'allocations-driver-mapping-beta',
      });
    } catch {
      addNotification({
        type: 'error',
        content: t('driver_mapping_download_error'),
      });
    }
  };

  const handleSubmit = (newDriverMappings: DriverMapping[]) => {
    let driverMappings: DriverMapping[] = [];
    if (driverModalAction === DriverMappingAction.EDIT) {
      driverMappings = getDriverMappingData(props.config.driverMappings, {
        filter: selectedItems[0],
        operation: DriverMappingAction.EDIT,
        driverMappings: newDriverMappings,
      });
    } else {
      driverMappings = getDriverMappingData(props.config.driverMappings, {
        operation: DriverMappingAction.ADD,
        driverMappings: newDriverMappings,
      });
    }
    props.onConfigChange({ type: 'UPDATE', payload: { driverMappings } });
    setSelectedItems([]);
    toggleModalDisplay();
  };

  const handleDelete = () => {
    props.onConfigChange({
      type: 'UPDATE',
      payload: {
        driverMappings: getDriverMappingData(props.config.driverMappings, {
          operation: DriverMappingAction.DELETE,
          filter: selectedItems[0],
        }),
      },
    });
  };

  return (
    <>
      {Boolean(driverModalAction) && (
        <DriverMappingModal
          config={props.config}
          driverMapping={
            driverModalAction === DriverMappingAction.EDIT
              ? selectedItems[0]
              : undefined
          }
          onClose={() => toggleModalDisplay()}
          onSubmit={handleSubmit}
          visible={Boolean(driverModalAction)}
        />
      )}
      <Table<DriverMapping>
        pagination
        preferences
        header={t('driver_mappings')}
        testid="driver-mapping-table"
        selectionType="single"
        loading={props.isLoading}
        columnDefinitions={colDefs}
        filteringProperties={filteringProperties(t)}
        contentDisplayOptions={getContentDisplayOptions(colDefs)}
        data={props.config?.driverMappings ?? []}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        empty={{
          title: t('empty_driver_mappings'),
          description: t('empty_driver_mappings_description'),
        }}
        buttons={
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              data-testid="download-driver-mapping"
              onClick={handleDownload}
            >
              {t('download_driver_mapping')}
            </Button>
            <Button
              disabled={!selectedItems.length}
              data-testid="delete-driver-mapping"
              onClick={handleDelete}
            >
              {t('delete_driver_mapping')}
            </Button>
            <Button
              disabled={!selectedItems.length}
              data-testid="edit-driver-mapping"
              onClick={() => toggleModalDisplay(DriverMappingAction.EDIT)}
            >
              {t('edit_driver_mapping')}
            </Button>
            <Button
              variant="primary"
              data-testid="add-driver-mapping"
              onClick={() => toggleModalDisplay(DriverMappingAction.ADD)}
            >
              {t('add_driver_mapping')}
            </Button>
          </SpaceBetween>
        }
      />
    </>
  );
};
