import { Action, MethodologyTree, Resource } from '@amzn/fae-auth-service';
import { SelectProps } from '@cloudscape-design/components';
import QueryKey from 'src/api/QueryKey';

export enum Business {
  GCF = 'GCF',
  GCF_INVENTORY = 'GCF_INVENTORY',
  GCF_THROUGHPUT = 'GCF_THROUGHPUT',
  GSF = 'GSF',
  GSF_INVENTORY = 'GSF_INVENTORY',
  GSF_THROUGHPUT = 'GSF_THROUGHPUT',
  SSD = 'SSD',
  SSD_INVENTORY = 'SSD_INVENTORY',
  SSD_THROUGHPUT = 'SSD_THROUGHPUT',
  AB = 'AB',
}
export interface AllocationConfiguration {
  methodologies: string[];
  regions: Record<string, string[]>;
  countries: Record<string, string[]>;
}

export const getAllocationConfigurations = (
  authorizedCombinations: MethodologyTree[] = [],
): AllocationConfiguration => {
  const methodologies: string[] = [];
  const regions: Record<string, string[]> = {};
  const countries: Record<string, string[]> = {};

  authorizedCombinations.forEach((authorizedCombination) => {
    const methodology = authorizedCombination.methodology ?? '';
    methodologies.push(methodology);
    if (!regions[methodology]) {
      regions[methodology] = [];
    }
    authorizedCombination.regions?.forEach((region) => {
      regions[methodology] = [...regions[methodology], ...Object.keys(region)];

      Object.keys(region).forEach((regionKey) => {
        const countryKey = `${methodology}_${regionKey}`;
        if (!countries[countryKey]) {
          countries[countryKey] = [];
        }
        countries[countryKey] = [
          ...countries[countryKey],
          ...region[regionKey],
        ];
      });
    });
  });

  return {
    methodologies,
    regions,
    countries,
  };
};

export const getBusinessOptions = (): SelectProps.Option[] =>
  Object.keys(Business).map((business) => ({
    label: business,
    value: business,
  }));

export const getMethodologyOptions = (
  config?: AllocationConfiguration,
): SelectProps.Option[] =>
  config?.methodologies?.map((methodology) => ({
    label: methodology,
    value: methodology,
  })) ?? [];

export const getRegionOptions = (
  methodology = '',
  config?: AllocationConfiguration,
): SelectProps.Option[] =>
  config?.regions[methodology]?.map((region) => ({
    label: region,
    value: region,
  })) ?? [];

export const getCountryOptions = (
  methodology = '',
  region = '',
  config?: AllocationConfiguration,
): SelectProps.Option[] =>
  config?.countries[`${methodology}_${region}`]?.map((country) => ({
    label: country,
    value: country,
  })) ?? [];

export const getQueryKey = (action: Action, business = ''): string[] => [
  QueryKey.GetPermissionsByBusiness,
  action,
  Resource.TASK,
  business,
];
