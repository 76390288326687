/* istanbul ignore file */

export default {
  CreateTask: 'CreateTask',
  DeleteTask: 'DeleteTask',
  EvaluateFeature: 'EvaluateFeature',
  ExecuteTask: 'ExecuteTask',
  GetDefaultValues: 'GetDefaultValues',
  GetDimensions: 'getDimensions',
  GetDriverMappings: 'GetDriverMappings',
  GetDownloadableDriverMapping: 'GetDownloadableDriverMapping',
  GetExecutionEvents: 'GetExecutionEvents',
  GetHierarchyNamesForDimension: 'GetHierarchyNamesForDimension',
  GetPermissions: 'GetPermissions',
  GetPermissionsByBusiness: 'GetPermissionsByBusiness',
  GetReportMetadata: 'GetReportMetadata',
  GetSliceInformation: 'GetSliceInformation',
  GetSliceInformationBatch: 'GetSliceInformationBatch',
  GetSlicesByEnvironmentAndScenario: 'GetSlicesByEnvironmentAndScenario',
  GetSlicesByDriverMapping: 'GetSlicesByDriverMapping',
  GetStaticDriverMetadata: 'GetStaticDriverMetadata',
  GetStaticDrivers: 'getStaticDrivers',
  GetTask: 'GetTask',
  GetTaskExecutionState: 'GetTaskExecutionState',
  HasPermission: 'HasPermission',
  HasPagePermission: 'HasPageAdminPermission',
  ListTaskExecutions: 'ListTaskExecutions',
  ListTasks: 'ListTasks',
  ListTasksWithLatestExecutionState: 'ListTasksWithLatestExecutionState',
  PutApprovalStatus: 'PutApprovalStatus',
  PutExecutionEvent: 'PutExecutionEvent',
  UpdateTask: 'UpdateTask',
  GetSlicesForSelectedScenario: 'GetSlicesForSelectedScenario',
  GetSliceNamesAssociatedWithDriverMappingId:
    'GetSliceNamesAssociatedWithDriverMappingId',
};
