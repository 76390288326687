import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormField, Select } from '@cloudscape-design/components';
import { scenarioOptions } from 'src/common/constants/environment';
import { SelectChangeEvent } from 'src/common/types/Events';
import { OptionDefinition } from 'src/common/types/OptionDefinition';

const ScenarioSelector = (props: {
  scenarioCallback: (selectedScenario: OptionDefinition) => void;
}) => {
  const { t } = useTranslation();
  const [scenario, setSelectedScenario] = useState<OptionDefinition | null>(
    null,
  );

  const handleScenarioChange = (event: SelectChangeEvent) => {
    const selectedOption = scenarioOptions.find(
      (option) => option.value === event.detail.selectedOption.value,
    );
    setSelectedScenario(selectedOption!);
    props.scenarioCallback(scenario!);
  };

  useEffect(() => {
    if (scenario) {
      props.scenarioCallback(scenario!);
    }
  }, [scenario]);

  return (
    <FormField label={t('scenario')} stretch>
      <Select
        data-testid="scenario"
        options={scenarioOptions}
        onChange={handleScenarioChange}
        selectedOption={scenario}
        placeholder={t('Choose a scenario')}
      />
    </FormField>
  );
};

export default ScenarioSelector;
