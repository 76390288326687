import AttributeEditor from '@cloudscape-design/components/attribute-editor';
import FormField from '@cloudscape-design/components/form-field';
import Select from '@cloudscape-design/components/select';
import { useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';
import QueryKey from 'src/api/QueryKey';
import { DropdownStatus } from 'src/common/types';
import {
  AttributeRemoveEvent,
  SelectChangeEvent,
} from 'src/common/types/Events';

interface DriverAttributeEditorProps {
  drivers: string[];
  onDriverChange: (drivers: string[]) => void;
}

export const DriverAttributeEditor: FC<DriverAttributeEditorProps> = (
  props,
) => {
  const { t } = useTranslation();
  const driverOptions = useQuery({
    queryKey: [QueryKey.GetStaticDrivers],
    queryFn: () => AllocationServiceApi.getStaticDrivers(),
    select: (data) =>
      data.drivers?.map((driver) => ({ label: driver, value: driver })),
  });

  const handleAddDriver = () => {
    props.onDriverChange([...props.drivers, '']);
  };

  const handleRemoveDriver = (event: AttributeRemoveEvent) => {
    props.onDriverChange(
      props.drivers.filter((_, index) => index !== event.detail.itemIndex),
    );
  };

  const handleDriverChange = (index: number) => (event: SelectChangeEvent) => {
    props.onDriverChange(
      props.drivers.map((driver, driverIndex) => {
        if (index === driverIndex) {
          return event.detail.selectedOption?.value ?? '';
        }
        return driver;
      }),
    );
  };

  return (
    <FormField stretch label={t('assign_drivers')}>
      <AttributeEditor
        data-testid="driver-attribute-editor"
        addButtonText={t('add_new_driver')}
        removeButtonText={t('remove')}
        empty={t('no_associated_driver')}
        disableAddButton={props.drivers.length >= 3}
        items={props.drivers}
        onAddButtonClick={handleAddDriver}
        onRemoveButtonClick={handleRemoveDriver}
        definition={[
          {
            control: (item, index) => (
              <Select
                statusType={
                  driverOptions.isPending
                    ? DropdownStatus.LOADING
                    : driverOptions.isError
                    ? DropdownStatus.ERROR
                    : DropdownStatus.FINISHED
                }
                loadingText={
                  driverOptions.isPending
                    ? t('static_drivers_loading')
                    : undefined
                }
                errorText={
                  driverOptions.isError ? t('static_drivers_error') : undefined
                }
                options={driverOptions.data}
                selectedOption={item ? { value: item } : null}
                placeholder={t('select_placeholder', { value: 'driver' })}
                onChange={handleDriverChange(index)}
              />
            ),
          },
        ]}
      />
    </FormField>
  );
};
