import Box from '@cloudscape-design/components/box';
import CollectionPreferences, {
  CollectionPreferencesProps,
} from '@cloudscape-design/components/collection-preferences';
import ColumnLayout from '@cloudscape-design/components/column-layout';
import React, { FC, Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { COASegment } from '@amzn/allocations-service';
import { filterOptions } from './config/driver-mapping-modal-preferences';
import {
  DimensionActionType,
  DimensionState,
  DimensionValue,
} from './utils/dimensionsReducer';
import { getDriverMappingValue } from './utils/driverMapping';
import { Segment } from './Segment';
import { PreferenceConfirmEvent } from 'src/common/types/Events';
import { DriverMapping } from 'src/common/types/DriverMapping';

interface DimensionsProps {
  dimensions: DimensionState;
  driverMapping?: DriverMapping;
  onDimensionChange: (id: string, value: Partial<DimensionValue>) => void;
  onFilterChange: (id: string, type: DimensionActionType) => void;
}

export const Dimensions: FC<DimensionsProps> = ({
  dimensions,
  driverMapping,
  onDimensionChange,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const options = useMemo(() => filterOptions(t), [t]);
  const [preferences, setPreferences] =
    useState<CollectionPreferencesProps.Preferences>({
      contentDisplay: Object.values(options),
    });

  const handlePreferenceConfirm = (event: PreferenceConfirmEvent) => {
    setPreferences(event.detail);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box variant="h2">{t('filters')}</Box>
        <CollectionPreferences
          data-testid="dimensions-preference"
          title={t('preferences')}
          preferences={preferences}
          onConfirm={handlePreferenceConfirm}
          contentDisplayPreference={{
            title: t('content_display'),
            description: t('content_display_description'),
            options: Object.values(options),
          }}
          confirmLabel={t('confirm')}
          cancelLabel={t('cancel')}
        />
      </div>
      <ColumnLayout columns={3}>
        {preferences.contentDisplay
          ?.filter((column) => column.visible)
          .map((column) => (
            <Fragment key={`${column.id}`}>
              <Segment
                initialValue={
                  driverMapping
                    ? getDriverMappingValue(
                        driverMapping,
                        column.id as COASegment,
                      )
                    : undefined
                }
                column={column.id as COASegment}
                dimension={dimensions[column.id as COASegment]}
                label={options[column.id as COASegment].label}
                onDimensionChange={onDimensionChange}
                onFilterChange={onFilterChange}
              />
            </Fragment>
          ))}
      </ColumnLayout>
    </>
  );
};
