import { COASegment } from '@amzn/allocations-service';
import FormField from '@cloudscape-design/components/form-field';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TreeCheckboxSelectionKeys, TreeSelectionEvent } from 'primereact/tree';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { DimensionActionType, DimensionValue } from './utils/dimensionsReducer';
import {
  getTreeNodes,
  getTreeNodesFromDimensionValues,
  getTreeNodesWithChildren,
} from './utils/segment';
import {
  TreeNode,
  TreeSelect,
} from 'src/common/components/tree-select/TreeSelect';
import { DropdownStatus } from 'src/common/types';
import QueryKey from 'src/api/QueryKey';
import { AllocationServiceApi } from 'src/api/AllocationServiceApi';

interface SegmentProps {
  column: COASegment;
  dimension: DimensionValue;
  initialValue?: string;
  label: string;
  onDimensionChange: (id: string, value: Partial<DimensionValue>) => void;
  onFilterChange: (id: string, type: DimensionActionType) => void;
}

export const Segment: FC<SegmentProps> = ({
  column,
  dimension,
  initialValue,
  label,
  onDimensionChange,
  onFilterChange,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [initialFilterReset, setInitialFilterReset] = useState(
    Boolean(initialValue),
  );

  const initiaData = useQuery({
    queryKey: [
      QueryKey.GetDimensions,
      column,
      dimension.hierarchyName,
      initialValue,
    ],
    queryFn: () =>
      AllocationServiceApi.getDimensions({
        dimensionName: column,
        hierarchyName: dimension.hierarchyName,
        filterValue: initialValue,
      }),
    enabled: Boolean(initialValue && dimension.hierarchyName),
  });

  useEffect(() => {
    if (!initiaData.data) {
      return;
    }

    const options = getTreeNodesFromDimensionValues(
      initiaData.data.dimensionValues,
    );

    const selectedKey =
      options.find((option) => {
        const keyFull = option.key as string;
        return keyFull.split('|').pop() === initialValue;
      })?.key ?? '';

    onDimensionChange(column, {
      options,
      status: DropdownStatus.FINISHED,
      value: { [selectedKey]: { checked: true } },
    });
  }, [initiaData.data, column, onDimensionChange, initialValue]);

  const handleLoadItems = async (node: TreeNode) => {
    if (node.children?.length || !node.expanded) {
      onDimensionChange(column, {
        options: getTreeNodes(
          dimension.options ?? [],
          `${node.key}`,
          Boolean(node.expanded),
        ),
        status: DropdownStatus.FINISHED,
      });
      return;
    }
    onDimensionChange(column, { status: DropdownStatus.LOADING });
    const response = await queryClient.fetchQuery({
      queryKey: [QueryKey.GetDimensions, column, dimension.hierarchyName, node],
      queryFn: () =>
        AllocationServiceApi.getDimensions({
          dimensionName: column,
          hierarchyName: dimension.hierarchyName,
          filterValue: `${node.key}`,
          level: Number(node.data?.level ?? 0) + 1,
        }),
    });

    const children = getTreeNodesFromDimensionValues(response.dimensionValues);

    const options = getTreeNodesWithChildren(
      dimension.options ?? [],
      `${node.key}`,
      children,
      Boolean(node.expanded),
    );
    onDimensionChange(column, {
      options,
      optionsBeforeAppliedFilters: initialFilterReset ? children : [],
      status: DropdownStatus.FINISHED,
    });
    setInitialFilterReset(false);
  };

  const handleFilterValueChange = useCallback(
    async (event: { value: string }) => {
      if (!dimension.hierarchyName) {
        return;
      }
      if (!event.value.length) {
        return onFilterChange(column, DimensionActionType.FILTER_RESET);
      }
      onFilterChange(column, DimensionActionType.FILTER);
      onDimensionChange(column, {
        status: DropdownStatus.LOADING,
      });
      const response = await queryClient.fetchQuery({
        queryKey: [
          QueryKey.GetDimensions,
          column,
          dimension.hierarchyName,
          event.value,
        ],
        queryFn: () =>
          AllocationServiceApi.getDimensions({
            dimensionName: column,
            hierarchyName: dimension.hierarchyName,
            filterValue: event.value,
            level: !event.value ? 1 : undefined,
          }),
      });

      onDimensionChange(column, {
        options: getTreeNodesFromDimensionValues(response.dimensionValues),
        status: DropdownStatus.FINISHED,
      });
    },
    [
      column,
      dimension.hierarchyName,
      onDimensionChange,
      queryClient,
      onFilterChange,
    ],
  );

  const handleChange = (event: TreeSelectionEvent) => {
    onDimensionChange(column, {
      value: event.value as TreeCheckboxSelectionKeys,
    });
  };

  const handleDismiss = (value: string) => {
    const selectedKeys = { ...dimension.value };
    delete selectedKeys[value];
    onDimensionChange(column, { value: selectedKeys });
  };

  return (
    <FormField label={label}>
      <TreeSelect
        testId={`${column}-dropdown`}
        placeholder={t('select_placeholder', {
          value: label,
        })}
        tokenLimit={2}
        onLoadItems={handleLoadItems}
        options={dimension.options}
        loading={dimension.status === DropdownStatus.LOADING}
        isLoadingInitialFilterValue={initiaData.isFetching}
        onChange={handleChange}
        selectedKeys={dimension.value}
        onFilterValueChange={handleFilterValueChange}
        onDismiss={handleDismiss}
        disabled={!dimension.hierarchyName}
        initialFilterValue={initialValue}
      />
    </FormField>
  );
};
