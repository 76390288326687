import { COASegment } from '@amzn/allocations-service';
import { TFunc } from 'src/common/types';

export const filterOptions = (
  t: TFunc,
): Record<COASegment, { id: COASegment; label: string; visible: boolean }> => ({
  [COASegment.ACCOUNT]: {
    id: COASegment.ACCOUNT,
    label: t('account'),
    visible: true,
  },
  [COASegment.COSTCENTER]: {
    id: COASegment.COSTCENTER,
    label: t('cost_center'),
    visible: true,
  },
  [COASegment.COMPANY]: {
    id: COASegment.COMPANY,
    label: t('company_code'),
    visible: true,
  },
  [COASegment.LOCATION]: {
    id: COASegment.LOCATION,
    label: t('location_code'),
    visible: false,
  },
  [COASegment.PRODUCT]: {
    id: COASegment.PRODUCT,
    label: t('product_code'),
    visible: false,
  },
  [COASegment.PROJECT]: {
    id: COASegment.PROJECT,
    label: t('project_code'),
    visible: false,
  },
  [COASegment.CHANNEL]: {
    id: COASegment.CHANNEL,
    label: t('channel_code'),
    visible: false,
  },
});
