import type {
  UpdateSliceAssociationRequest,
  UpdateSliceAssociationResponse,
} from '@amzn/allocations-service';
import React, { Dispatch } from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import type { Task, UpdateTaskRequest } from '@amzn/taskscheduling-service';
import {
  getSlicedAllocationTaskInput,
  getUpdateTaskRequestWithSchedule,
} from 'src/common/utils/task';
import { getUpdateSliceAssociationRequest } from 'src/common/utils/slice';
import { SliceGroup } from 'src/shadow-pnl-allocations/pages/configure-slice-order-page/types/SliceGroup';

type SetStateFn<T> = Dispatch<React.SetStateAction<T>>;

class UpdateHandler {
  async handleDriverMappingUpdate(
    item: SliceGroup,
    newValue: any,
    setSliceSequence: SetStateFn<string[]>,
    setRowItems: SetStateFn<SliceGroup[]>,
    mutateAsyncFn: UseMutationResult<
      UpdateSliceAssociationResponse,
      Error,
      UpdateSliceAssociationRequest,
      unknown
    >,
  ) {
    await mutateAsyncFn.mutateAsync(
      getUpdateSliceAssociationRequest(
        item.sliceId,
        newValue,
        item.driverMappingId,
      ),
    );
    //Reset page to re-render everything and re-fetch
    setSliceSequence([]);
    setRowItems([]);
  }

  async handleSliceOrderUpdate(
    item: SliceGroup,
    newValue: any,
    sliceSequence: string[],
    scenario: { label: string; value: string },
    mutateAsyncFn: UseMutationResult<void, Error, UpdateTaskRequest, unknown>,
    taskList: Task[] | undefined,
  ) {
    const newIndex = parseInt(newValue, 10) - 1;
    const oldIndex = parseInt(item.sliceOrder, 10) - 1;

    // Shallow copy for resetting the state
    const updatedSequence = [...sliceSequence];
    const [removedElement] = updatedSequence.splice(oldIndex, 1);
    updatedSequence.splice(newIndex, 0, removedElement);
    const taskIndex =
      scenario.value ===
      taskList?.[0]?.input?.slicedAllocationTaskInput?.scenario
        ? 0
        : 1;
    const taskId = taskList?.[taskIndex]?.taskId;
    const description = taskList?.[taskIndex]?.taskId;
    const taskScenario =
      taskList?.[taskIndex]?.input?.slicedAllocationTaskInput?.scenario;
    const taskEnvironment =
      taskList?.[taskIndex]?.input?.slicedAllocationTaskInput?.environment;
    const taskMethodology =
      taskList?.[taskIndex]?.input?.slicedAllocationTaskInput?.methodology;
    const taskBusiness =
      taskList?.[taskIndex]?.input?.slicedAllocationTaskInput?.business;
    const taskRegion =
      taskList?.[taskIndex]?.input?.slicedAllocationTaskInput?.region;

    await mutateAsyncFn.mutateAsync(
      getUpdateTaskRequestWithSchedule(
        taskId,
        description,
        getSlicedAllocationTaskInput(
          taskScenario,
          updatedSequence,
          taskEnvironment,
          taskMethodology,
          taskBusiness,
          taskRegion,
        ),
        taskList?.[taskIndex]?.schedule,
      ),
    );
  }
}

export default new UpdateHandler();
