import React from 'react';
import { Input, Select, TableProps } from '@cloudscape-design/components';
import { SliceGroup } from './SliceGroup';
import { OptionDefinition } from 'src/common/types/OptionDefinition';

function createOrderOptions(length: number): OptionDefinition[] {
  return Array.from({ length }, (_, index) => ({
    label: (index + 1).toString(),
    value: (index + 1).toString(),
  }));
}

export const getSliceOrderColumnWithEdit = (
  sliceSequenceLength: number,
  translatedHeader: string,
): TableProps.ColumnDefinition<SliceGroup> => {
  const orderOptions = createOrderOptions(sliceSequenceLength);
  return {
    id: 'sliceOrder',
    header: translatedHeader,
    cell: (item: SliceGroup) => item.sliceOrder,
    minWidth: 120,
    editConfig: {
      editIconAriaLabel: 'editable',
      editingCell: (item, { currentValue, setValue }) => {
        const itemOrder: string = currentValue ?? item.sliceOrder;
        return (
          <Select
            data-testid={`slice-order-table-sliceOrder-${item.sliceOrder}`}
            expandToViewport
            selectedOption={
              orderOptions.find(
                (option: OptionDefinition) => option.value === itemOrder,
              ) ?? null
            }
            onChange={(event) => {
              setValue(event.detail.selectedOption.value);
            }}
            options={orderOptions}
          />
        );
      },
    },
  };
};

export const getSliceNameColumn = (
  translatedHeader: string,
): TableProps.ColumnDefinition<SliceGroup> => ({
  id: 'sliceName',
  header: translatedHeader,
  cell: (item: SliceGroup) => item.sliceName,
  minWidth: 500,
});

export const getDriverMappingIdColumnWithEdit = (
  translatedHeader: string,
): TableProps.ColumnDefinition<SliceGroup> => ({
  id: 'driverMappingId',
  header: translatedHeader,
  cell: (item: SliceGroup) => item.driverMappingId,
  minWidth: 300,
  editConfig: {
    editIconAriaLabel: 'editable',
    editingCell: (item, { currentValue, setValue }) => (
      <Input
        data-testid={`slice-order-table-driverMappingId-${item.sliceOrder}`}
        value={currentValue ?? item.driverMappingId}
        onChange={(event) => setValue(event.detail.value)}
      />
    ),
  },
});
