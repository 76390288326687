import {
  GetDefaultValuesResponse,
  GetDimensionsRequest,
  GetDimensionsResponse,
  GetDownloadableDriverMappingRequest,
  GetDownloadableDriverMappingResponse,
  GetDriverMappingsRequest,
  GetDriverMappingsResponse,
  GetDriverMappingsV2Request,
  GetDriverMappingsV2Response,
  GetExecutionEventsRequest,
  GetExecutionEventsResponse,
  GetHierarchyNamesForDimensionRequest,
  GetHierarchyNamesForDimensionResponse,
  GetReportMetadataRequest,
  GetReportMetadataResponse,
  GetSliceRequest,
  GetSliceResponse,
  GetSlicesByDriverMappingRequest,
  GetSlicesByDriverMappingResponse,
  GetSlicesByEnvironmentAndScenarioRequest,
  GetSlicesByEnvironmentAndScenarioResponse,
  GetStaticDriverMetadataRequest,
  GetStaticDriverMetadataResponse,
  GetStaticDriversResponse,
  PutApprovalStatusRequest,
  PutApprovalStatusResponse,
  UpdateSliceAssociationRequest,
  UpdateSliceAssociationResponse,
  UpdateDriverMappingEntriesRequest,
  UpdateDriverMappingEntriesResponse,
} from '@amzn/allocations-service';
import { Api } from './Api';

const API = new Api('FinanceAllocationsService');

export const AllocationServiceApi = {
  getReportMetadata(
    request: GetReportMetadataRequest,
  ): Promise<GetReportMetadataResponse> {
    return API.post('/getReportMetadata', request);
  },
  getStaticDriverMetadata(
    request: GetStaticDriverMetadataRequest,
  ): Promise<GetStaticDriverMetadataResponse> {
    return API.post('/getStaticDriverMetadata', request);
  },
  putApprovalStatus(
    request: PutApprovalStatusRequest,
  ): Promise<PutApprovalStatusResponse> {
    return API.post('/putApprovalStatus', request);
  },
  getExecutionEvents(
    request: GetExecutionEventsRequest,
  ): Promise<GetExecutionEventsResponse> {
    return API.post('/getExecutionEvents', request);
  },
  getDriverMappings(
    request: GetDriverMappingsRequest,
  ): Promise<GetDriverMappingsResponse> {
    return API.post('/driverMappings', request);
  },
  getDimensions(request: GetDimensionsRequest): Promise<GetDimensionsResponse> {
    return API.post('/getDimensions', request);
  },
  getStaticDrivers: (): Promise<GetStaticDriversResponse> =>
    API.get('/staticDrivers'),
  getDefaultValuesByCOASegment: (): Promise<GetDefaultValuesResponse> =>
    API.get('/defaultValues'),
  getHierarchyNamesForDimension: (
    request: GetHierarchyNamesForDimensionRequest,
  ): Promise<GetHierarchyNamesForDimensionResponse> =>
    API.post('/getHierarchyNamesForDimension', request),
  getSliceInformation: (request: GetSliceRequest): Promise<GetSliceResponse> =>
    API.post('/slice', request),
  getSliceInformationBatch: (
    requests: GetSliceRequest[],
  ): Promise<GetSliceResponse>[] =>
    requests.map(
      (request) => API.post('/slice', request) as Promise<GetSliceResponse>,
    ),
  getSlicesByEnvironmentAndScenario: (
    request: GetSlicesByEnvironmentAndScenarioRequest,
  ): Promise<GetSlicesByEnvironmentAndScenarioResponse> =>
    API.post('/slices/byEnvAndScenario', request),
  getDownloadableDriverMappingPath: (
    request: GetDownloadableDriverMappingRequest,
  ): Promise<GetDownloadableDriverMappingResponse> =>
    API.post('/driverMappings/download', request),
  updateSliceAssociation: (
    request: UpdateSliceAssociationRequest,
  ): Promise<UpdateSliceAssociationResponse> =>
    API.post('/slice/updateAssociation', request),
  getSlicesAssociatedToDriverMapping: (
    request: GetSlicesByDriverMappingRequest,
  ): Promise<GetSlicesByDriverMappingResponse> =>
    API.post('/slices/byDriverMapping', request),
  getDriverMappingsV2: (
    request: GetDriverMappingsV2Request,
  ): Promise<GetDriverMappingsV2Response> =>
    API.post('/driverMappings/v2', request),
  updateDriverMappingEntries: (
    request: UpdateDriverMappingEntriesRequest,
  ): Promise<UpdateDriverMappingEntriesResponse> =>
    API.post('/driverMappingEntries/update', request),
};
