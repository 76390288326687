/**
 * Configuration required for Amplify.
 * Ref: https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/
 */

export const envConfig = {
  Auth: {
    // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:5eff42b5-5183-46bc-b36e-6d92ba094e17',

    // (required)- Amazon Cognito Region
    region: 'us-west-2',

    // (optional) - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_Bptlgkas9',

    // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
    userPoolWebClientId: '5qlr9dab49o668h4nrmm7mv706',

    // (optional) - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // (optional) - Hosted UI configuration
    oauth: {
      domain: 'finance-allocation-access-control-beta' + '.auth.us-west-2.amazoncognito.com',
      redirectSignIn: 'https://' + window.location.host + '/',
      redirectSignOut: 'https://' + window.location.host + '/',
      scope: ['openid'],
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: 'FinanceAllocationsService',
        endpoint: 'https://beta-api.allocations.finergy.amazon.dev',
      },
      {
        name: 'TaskSchedulingService',
        endpoint: 'https://beta-api.taskscheduling.finergy.amazon.dev',
      },
      {
        name: 'FAEAuthService',
        endpoint: 'https://beta-authz.allocations.finergy.amazon.dev',
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: 'allocations-driver-mapping-beta',
      region: 'us-west-2',
    },
  },
};
