import React, { startTransition, useEffect, useRef, useState } from 'react';
import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import Table from '@amzn/awsui-components-react/polaris/table';
import { useTranslation } from 'react-i18next';
import {
  PropertyFilterProperty,
  useCollection,
} from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { TableProps as TableComponentProps } from '@cloudscape-design/components/table';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {
  Button,
  NonCancelableCustomEvent,
  PaginationProps,
} from '@cloudscape-design/components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
//TODO: Remove dependency from below import to make this component unaware of service model to make it truely data source agnostic
import { DriverMappingRecord } from '@amzn/allocations-service';
import {
  DEFAULT_PREFERENCES,
  paginationLabels,
} from 'src/common/components/server-side-table/table-config';
import { getContentDisplayOptions } from 'src/common/utils/table';
import { TableChangeEvent } from 'src/common/types/Events';
import { DriverMappingAction } from 'src/common/components/driver-mapping-components/utils/driverMapping';
import { useNotificationContext } from 'src/common/provider/NotificationProvider';
import { defaultConfig } from 'src/common/types/Config';
import { TablePreferences } from 'src/common/components/TablePreferences';
import DriverMappingModal from 'src/common/components/driver-mapping-components/DriverMappingModal';
import { DriverMapping } from 'src/common/types/DriverMapping';
import { getDrivers } from 'src/common/components/driver-mapping-components/config/driver-mapping-table';
import { useMetrics } from 'src/common/provider/MetricsProvider';
import { PageAction } from 'src/common/types/PageAction';
import { TableEmptyState } from 'src/common/components/TableEmptyState';

export interface NextPageResponse {
  driverMappings: DriverMappingRecord[];
  paginationToken: string | undefined;
}

export interface ServerSideTableProps {
  driverMappingId: string;
  columnDefinitions: TableComponentProps.ColumnDefinition<DriverMappingRecord>[];
  header?: string;
  empty?: { title: string; description: string };
  filteringProperties?: PropertyFilterProperty[];
  testId: string;
  handleDownloadCallback: () => Promise<void>;
  fetchNextPage: (
    pageSize: number,
    paginationToken?: string,
  ) => Promise<NextPageResponse>;
  updateDriverMappingEntries: (
    driverMappingEntries: Map<DriverMappingAction, DriverMappingRecord[]>,
  ) => Promise<void>;
}

/**
 * This component renders driver mappings for a given driverMappingId and accepts callbacks to fetch and update driver mappings.
 * TODO: Rename this component to DataSourceAgnosticDriverMappingTable
 */
export const ServerSideTable = (props: ServerSideTableProps) => {
  const { t } = useTranslation();
  const { addNotification } = useNotificationContext();
  const queryClient = useQueryClient();
  const metrics = useMetrics();

  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [selectedItems, setSelectedItems] = useState<DriverMappingRecord[]>([]);
  const [pendingActionSet, setPendingActionSet] = useState<
    Map<DriverMappingAction, DriverMappingRecord[]>
  >(new Map());

  const [driverMappings, setDriverMappings] = useState<DriverMappingRecord[]>(
    [],
  );
  const [showDriverMappingModalForAction, setShowDriverMappingModalForAction] =
    useState<DriverMappingAction>();
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [isPageLoadInProgress, setIsPageLoadInProgress] =
    useState<boolean>(false);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState<boolean>(false);
  const currentPageRef = useRef<{
    lastPageIndex?: number;
    pagesLoadedSoFar: number;
    pageSizeSelected: number;
  }>({
    pagesLoadedSoFar: 0,
    pageSizeSelected: preferences.pageSize!,
  });

  /**
   * Resets the state to default values and causes the table to render from first page with latest data
   */
  function resetPageState() {
    queryClient.invalidateQueries().then(() => {
      currentPageRef.current = {
        pagesLoadedSoFar: 0,
        pageSizeSelected: preferences.pageSize!,
      };
      setDriverMappings([]);
      setCurrentPageIndex(1);
      setPendingActionSet(new Map());
      setSelectedItems([]);
    });
  }

  /**
   * This function fetches the pagination token from page query cache response.
   */
  function getPaginationToken(pageNumber: number) {
    if (currentPageIndex === 1) {
      return undefined;
    }
    const queryData: NextPageResponse = queryClient.getQueryData([
      'getDriverMapping',
      props.driverMappingId,
      pageNumber,
      currentPageRef.current.pageSizeSelected,
    ])!;
    return queryData.paginationToken!;
  }

  const {
    data: currentPageData,
    isSuccess: hasFetchedCurrentPageSuccessfully,
    isError: hasFetchingCurrentPageFailed,
  } = useQuery({
    queryKey: [
      'getDriverMapping',
      props.driverMappingId,
      currentPageIndex,
      currentPageRef.current.pageSizeSelected,
    ],
    queryFn: () =>
      props.fetchNextPage(
        preferences.pageSize!,
        getPaginationToken(currentPageIndex - 1),
      ),
  });

  function applyNextPageContent(nextPageResponse: NextPageResponse) {
    currentPageRef.current = {
      ...currentPageRef.current,
      lastPageIndex: !nextPageResponse.paginationToken
        ? currentPageIndex
        : undefined,
      pagesLoadedSoFar: Math.max(
        currentPageRef.current.pagesLoadedSoFar,
        currentPageIndex,
      ),
    };
    setDriverMappings([...driverMappings, ...nextPageResponse.driverMappings!]);
    setIsPageLoadInProgress(false);
  }

  useEffect(() => {
    if (hasFetchingCurrentPageFailed) {
      metrics.publishCounter(
        'DriverMapping.GetDriverMapping',
        PageAction.Failure,
        1,
      );
      addNotification({
        type: 'error',
        content: t('driver_mapping_fetch_error'),
      });
      setIsPageLoadInProgress(false);
    }
  }, [hasFetchingCurrentPageFailed]);

  useEffect(() => {
    if (hasFetchedCurrentPageSuccessfully) {
      applyNextPageContent(currentPageData!);
    }
  }, [hasFetchedCurrentPageSuccessfully]);

  useEffect(() => {
    if (currentPageRef.current.pageSizeSelected !== preferences.pageSize) {
      currentPageRef.current = {
        ...currentPageRef.current,
        pageSizeSelected: preferences.pageSize!,
      };
      resetPageState();
    }
  }, [preferences.pageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    currentPageData ? currentPageData.driverMappings! : [],
    {
      pagination: { pageSize: preferences.pageSize },
      selection: {},
    },
  );

  const handlePreviousPage = () => {
    if (!isFirstPage()) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };

  const handleNextPage = () => {
    if (
      !isLastPage() &&
      !isPageLoadInProgress &&
      currentPageRef.current.pagesLoadedSoFar < currentPageIndex
    ) {
      //Ensuring that the next page button is disabled if the page is not already loaded
      //This state will be reset when the fetched driver mapping page is applied on the table
      setIsPageLoadInProgress(true);
    }
    setCurrentPageIndex(currentPageIndex + 1);
  };

  function handlePageJump(
    changeEvent: NonCancelableCustomEvent<PaginationProps.ChangeDetail>,
  ) {
    //This has to be a jump to an already loaded page as the table is "open ended"
    setCurrentPageIndex(changeEvent.detail.currentPageIndex);
  }

  const handleDownload = async () => {
    try {
      await props.handleDownloadCallback();
    } catch {
      metrics.publishCounter('DriverMapping.Download', PageAction.Failure, 1);
      addNotification({
        type: 'error',
        content: t('driver_mapping_download_error'),
      });
    }
  };

  const handleSelectionChange = ({
    detail,
  }: TableChangeEvent<DriverMappingRecord>) => {
    setSelectedItems(detail.selectedItems);
  };

  function isFirstPage() {
    return currentPageIndex === 1;
  }

  function hasReachedLastPage() {
    return !!currentPageRef.current.lastPageIndex;
  }

  function isLastPage(pageNumber: number = currentPageIndex) {
    return (
      hasReachedLastPage() &&
      currentPageRef.current.lastPageIndex === pageNumber
    );
  }

  function isTableEmpty() {
    return !driverMappings || !driverMappings.length;
  }

  //TODO: Use Mutation to show loader and disable other "write" options while driver mapping is being updated
  async function handleSubmitDriverMappingUpdates() {
    setIsUpdateInProgress(true);
    await props
      .updateDriverMappingEntries(pendingActionSet)
      .then(() => {
        addNotification({
          type: 'success',
          content: t('driver_mapping_update_success'),
        });
        resetPageState();
      })
      .catch(() => {
        metrics.publishCounter('DriverMapping.Update', PageAction.Failure, 1);
        addNotification({
          type: 'error',
          content: t('driver_mapping_update_error'),
        });
      })
      .finally(() => {
        setIsUpdateInProgress(false);
      });
  }

  function appendToPendingActionSet(
    itemsToUpdate: {
      action: DriverMappingAction;
      records: DriverMappingRecord[];
    }[],
  ) {
    const pendingActionSetCopy = new Map(pendingActionSet);
    itemsToUpdate.forEach((item) => {
      const existingPendingRecords =
        pendingActionSetCopy.get(item.action) ?? [];
      pendingActionSetCopy.set(item.action, [
        ...existingPendingRecords,
        ...item.records,
      ]);
    });
    setPendingActionSet(pendingActionSetCopy);
  }

  function toDriverMappingRecords(
    driverMappings: DriverMapping[],
  ): DriverMappingRecord[] {
    return driverMappings.map((driverMapping) => ({
      account: driverMapping.account,
      costCenter: driverMapping.costCenter,
      productCode: driverMapping.productCode,
      projectCode: driverMapping.projectCode,
      locationCode: driverMapping.locationCode,
      channelCode: driverMapping.channelCode,
      companyCode: driverMapping.companyCode,
      drivers: getDrivers(driverMapping),
      ...driverMapping,
    }));
  }

  const handleDeleteDriverMappingEntries = (
    driverMappings: DriverMapping[],
  ) => {
    appendToPendingActionSet([
      {
        action: DriverMappingAction.DELETE,
        records: toDriverMappingRecords(driverMappings),
      },
    ]);
  };

  const toggleModalDisplay = (action?: DriverMappingAction) => {
    startTransition(() => setShowDriverMappingModalForAction(action));
  };

  function handleEditDriverMappingEntries(
    driverMappings: DriverMapping[],
  ): void {
    appendToPendingActionSet([
      {
        action: DriverMappingAction.EDIT,
        records: toDriverMappingRecords(driverMappings),
      },
      //Assuming that the update will happen when only one item is selected
      {
        action: DriverMappingAction.DELETE,
        records: toDriverMappingRecords([selectedItems[0]]),
      },
    ]);
    toggleModalDisplay();
  }

  function handleAddDriverMappingEntries(driverMappings: DriverMapping[]) {
    appendToPendingActionSet([
      {
        action: DriverMappingAction.ADD,
        records: toDriverMappingRecords(driverMappings),
      },
    ]);
    toggleModalDisplay();
  }

  const driverMappingActionHandlers = new Map<
    DriverMappingAction,
    (driverMappings: DriverMapping[]) => void
  >([
    [DriverMappingAction.EDIT, handleEditDriverMappingEntries],
    [DriverMappingAction.DELETE, handleDeleteDriverMappingEntries],
    [DriverMappingAction.ADD, handleAddDriverMappingEntries],
  ]);

  function handleCancelDriverMapping(): void {
    setPendingActionSet(new Map());
  }

  function shouldEnableEditDriverMappingEntryButton() {
    return (
      selectedItems.length === 1 && !isPageLoadInProgress && !isUpdateInProgress
    ); //Can only edit when only a single item is selected
  }

  function shouldEnableDeleteDriverMappingEntriesButton() {
    return (
      selectedItems.length > 0 && !isPageLoadInProgress && !isUpdateInProgress
    );
  }

  function shouldEnableUpdateButton() {
    return (
      getPendingSetSize() > 0 && !isPageLoadInProgress && !isUpdateInProgress
    );
  }

  function shouldShowDriverMappingEntryModel() {
    return (
      showDriverMappingModalForAction &&
      (DriverMappingAction.ADD === showDriverMappingModalForAction ||
        DriverMappingAction.EDIT === showDriverMappingModalForAction)
    );
  }

  function pendingActionSetSizeForAction(action: DriverMappingAction) {
    return pendingActionSet.get(action)?.length ?? 0;
  }

  function getPendingSetSize() {
    return (
      pendingActionSetSizeForAction(DriverMappingAction.ADD) +
      pendingActionSetSizeForAction(DriverMappingAction.EDIT) +
      pendingActionSetSizeForAction(DriverMappingAction.DELETE)
    );
  }

  return (
    <>
      {
        /**
         * DriverMappingModal will be visible on ADD / EDIT button clicks.
         */
        shouldShowDriverMappingEntryModel() && (
          <DriverMappingModal
            config={defaultConfig}
            driverMapping={
              showDriverMappingModalForAction === DriverMappingAction.EDIT
                ? selectedItems[0]
                : undefined
            }
            onClose={() => toggleModalDisplay()}
            onSubmit={
              driverMappingActionHandlers.get(showDriverMappingModalForAction!)!
            }
            visible={Boolean(showDriverMappingModalForAction)}
          />
        )
      }

      <Table
        data-testid={props.testId}
        {...collectionProps}
        enableKeyboardNavigation={true}
        loading={isPageLoadInProgress || isUpdateInProgress}
        selectedItems={selectedItems}
        empty={
          isTableEmpty() ? (
            <TableEmptyState
              title={t('empty_mappings')}
              subtitle={t('empty_mappings_description')}
            />
          ) : undefined
        }
        items={items}
        onSelectionChange={handleSelectionChange}
        columnDefinitions={props.columnDefinitions}
        columnDisplay={preferences.contentDisplay}
        selectionType="multi"
        variant="full-page"
        stickyHeader={true}
        resizableColumns={true}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        stickyColumns={preferences.stickyColumns}
        header={
          <Header
            variant="awsui-h1-sticky"
            counter={
              hasReachedLastPage()
                ? `(${driverMappings.length})`
                : `(${driverMappings.length}+)`
            }
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  data-testid="download-driver-mapping"
                  onClick={handleDownload}
                >
                  {t('download_driver_mapping')}
                </Button>
                <Button
                  disabled={!shouldEnableDeleteDriverMappingEntriesButton()}
                  data-testid="delete-driver-mapping"
                  onClick={() => {
                    driverMappingActionHandlers.get(
                      DriverMappingAction.DELETE,
                    )!(selectedItems);
                  }}
                >
                  {t('delete_driver_mapping')}
                </Button>
                <Button
                  disabled={!shouldEnableEditDriverMappingEntryButton()}
                  data-testid="edit-driver-mapping"
                  onClick={() => toggleModalDisplay(DriverMappingAction.EDIT)}
                >
                  {t('edit_driver_mapping')}
                </Button>
                <Button
                  variant="primary"
                  data-testid="add-driver-mapping"
                  onClick={() => toggleModalDisplay(DriverMappingAction.ADD)}
                  disabled={isPageLoadInProgress}
                >
                  {t('add_driver_mapping')}
                </Button>
              </SpaceBetween>
            }
          >
            {props.header}
          </Header>
        }
        loadingText={t('table_loading')}
        //TODO: Pass selected filters here
        pagination={
          <Pagination
            {...paginationProps}
            currentPageIndex={currentPageIndex}
            onNextPageClick={handleNextPage}
            onPreviousPageClick={handlePreviousPage}
            onChange={handlePageJump}
            openEnd={!hasReachedLastPage()}
            pagesCount={currentPageRef.current.pagesLoadedSoFar}
            disabled={isPageLoadInProgress || isUpdateInProgress}
            ariaLabels={paginationLabels}
          />
        }
        preferences={
          <TablePreferences
            contentDisplayOptions={getContentDisplayOptions(
              props.columnDefinitions,
            )}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        }
        sortingDisabled={true}
        footer={
          <SpaceBetween direction="horizontal" size="xs" alignItems="end">
            <Button
              variant="primary"
              disabled={!shouldEnableUpdateButton()}
              data-testid="update-driver-mapping"
              onClick={handleSubmitDriverMappingUpdates}
            >
              {t('update_driver_mapping')}({getPendingSetSize()})
            </Button>
            <Button
              variant="normal"
              disabled={!selectedItems.length}
              data-testid="cancel-driver-mapping"
              onClick={handleCancelDriverMapping}
            >
              {t('cancel_driver_mapping')}
            </Button>
          </SpaceBetween>
        }
      />
    </>
  );
};
